<script setup lang="ts">
import { SearchIcon } from '@glow/shared/components/icons';
import AppPopoverBody from '@glow/shared/components/ui/AppPopover/AppPopoverBody.vue';
import { SearchResults } from '@/features/partners';
import { usePopover } from '@glow/shared/components/ui/AppPopover/usePopover';
import AppInput from '@glow/shared/components/ui/AppInput/AppInput.vue';
import { useBreakpoints, useEventListener } from '@vueuse/core';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import AppLayoutMobileSearchScreen from './AppLayoutMobileSearchScreen.vue';
import { useBodyScrolling } from '@glow/shared/store/bodyScrolling';

const searchQuery = ref('');
const referenceRef = ref<InstanceType<typeof AppInput>>();
const popoverRef = ref<InstanceType<typeof AppPopoverBody>>();
const isMobile = useBreakpoints(breakpoints).smaller('md');
const isSearchScreenVisible = ref(false);
const { isVisible: isPopoverVisible, popoverStyles } = usePopover(
  {
    popover: toRef(() => popoverRef.value?.el),
    reference: toRef(() => referenceRef.value?.$el),
  },
  {
    distance: 32,
    sameWidth: true,
  }
);
const { enableBodyScrolling, disableBodyScrolling } = useBodyScrolling();

onMounted(() => {
  useEventListener(document, 'click', (event) => {
    if (!event.target) return;
    const clickedWithinInput = referenceRef.value?.$el.contains(event.target);
    const clickedWithinPopover = popoverRef.value?.el?.contains(
      event.target as Node
    );
    isPopoverVisible.value =
      !isMobile.value && (clickedWithinInput || clickedWithinPopover);
  });
});

function onClickOnInput(e: InputEvent) {
  if (!isMobile.value) return;
  e.preventDefault();
  isSearchScreenVisible.value = true;
  disableBodyScrolling();
  return false;
}
function hideSearchScreen() {
  isSearchScreenVisible.value = false;
  enableBodyScrolling();
}
</script>

<template>
  <AppInput
    ref="referenceRef"
    v-model="searchQuery"
    fluid
    placeholder="Поиск"
    static-placeholder
    @click="onClickOnInput"
  >
    <template #prepend>
      <AppIcon :icon="SearchIcon" />
    </template>
  </AppInput>

  <AppPopoverBody
    ref="popoverRef"
    v-model="isPopoverVisible"
    :style="popoverStyles"
    :with-arrow="false"
  >
    <SearchResults
      :search-query="searchQuery"
      @close="isPopoverVisible = false"
    />
  </AppPopoverBody>

  <ClientOnly>
    <Teleport to="#layout">
      <AppLayoutMobileSearchScreen
        v-if="isMobile && isSearchScreenVisible"
        class="search-screen"
        @close="hideSearchScreen()"
      />
    </Teleport>
  </ClientOnly>
</template>

<style scoped lang="scss">
.search-screen {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 var(--app-spacing-4);
  padding-top: calc(var(--safe-area-inset-top) + var(--app-spacing-4));
  padding-bottom: calc(var(--safe-area-inset-bottom) + var(--app-spacing-4));
}
</style>
